
 const SubjectStatistics = [
    {
        id : 1,
        value : "2022",
        subjectData : "2022"
    },
    {
        id : 2,
        value : "2021",
        subjectData : "2021"
    },
    {
        id : 3,
        value : "2020",
        subjectData : "2020"
    },
    {
        id : 4,
        value : "2019",
        subjectData : "2019"
    },
    {
        id : 5,
        value : "2018",
        subjectData : "2018"
    }
];



export default SubjectStatistics;