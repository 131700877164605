import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../DissertationCapstone.css';

function BannerAndFirstQuestion() {
    const navigate = useNavigate();
  return (
    <>
    <section className="capstoneProjectBannerImageSection">
        <div className="container-fluid dissertationCapstoneBannerImage">
            <div className="row justify-content-center">
                <div className="col-md-8 bannerImageHeadingSectionInCapstone">
                    <h1><span>CAPSTONE PROJECT</span></h1>
                    <div className="below-heading-line-capstone-banner-heading"><span></span></div>
                    <h3>Innovating Across The <br />Globe</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. </p>
                    <button className="capstoneWriteMyProjectButton" onClick={() => navigate("/getQuote")}>Write My Project</button>
                </div>
                <div className="col-md-4 bannerImageVacantSection"></div>
            </div>
        </div>
    </section>
    <section className="capstoneProjectFirstQuestionSection">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 capstoneFirstQuestionHeadingAndPara">
                    <h2>WHAT IS CAPSTONE PROJECT ?</h2>
                    <p>A capstone project is an educational endeavor that requires independent research, critical thinking, and problem-solving skills. It is an integral part of the college experience, allowing students to apply the knowledge they have acquired during their studies and create a tangible project that showcases their skills. Dissertation help tutors have to explain what a capstone project is, how it is different from other academic projects, and how it can be beneficial for students. They discuss the various components of a capstone project, such as the research process, the writing process, the presentation, and the assessment. Finally, dissertation writing services also explain various challenges and opportunities that come with completing a capstone project. With this information, you will get an overview of the benefits of completing a capstone project and the best practices for success.</p>
                    <p>A capstone project is a culminating assessment that represents a student's mastery of a particular academic discipline or program of study. It is often completed at the end of an academic program and is designed to test the students' knowledge and skills acquired throughout the program. Dissertation writing services provided by experienced tutors will discuss what a capstone project is, why they are important, and the different types of capstone projects. It will also examine what is required to successfully complete a capstone project and the rewards that come with completing one. Finally, it will provide tips on how to create an effective capstone project. By the end dissertation paper, readers should have a clearer understanding of what a capstone project is and how to successfully complete one.</p>
                    <p>A capstone project is an important task that students are required to complete in order to graduate from a college or university. Furthermore, the capstone project is a unique opportunity for students to demonstrate their critical thinking, problem solving, and communication skills. ‘Hire someone to write my dissertation’ services offered by tutors, provide an overview of what a capstone project is, what it entails, and why it is important. It provides key details of the common components of a capstone project, including the research process, writing process, and presentation. Finally, they also provide some tips and strategies for successfully completing a capstone project.</p>
                    <p>Completing a capstone project is an essential requirement for graduating from college or university. It ensures that students will be able to demonstrate the knowledge and skills they have acquired during their studies. It also offers a unique opportunity to showcase their critical thinking, problem-solving, and communication abilities. Dissertation writingservices will provide an overview of what a capstone project is what it entails, and why it is important. Additionally, it will discuss the common components of a capstone project, such as the research, writing, and presentation process. Lastly, tutors provide some advice and strategies for successfully completing a capstone project.</p>
                    <p>Before graduating from college or university, students must complete a capstone project. Experienced tutors act as guide to cover details about what a capstone project is, what it involves, and why it matters. It will also explain the common components of a capstone project, such as research, writing, and presentation. To help you succeed, tutors provide some tips and strategies for getting the most out of the process.</p>
                    <p>It's undeniable that a capstone project is a critical part of student life. The core elements of the dissertation paper should provide insights on the research question and explain the manner in which further analysis of the topic can be engaged. All these elements would be used for enhancing the knowledge base of the students and other researchers on the topic. These elements would be used for enhancing the basis in which long term management of the quality of the knowledge of the students would be managed. The simplicity of the capstone project should engage the audience in a clear manner while ensuring that the review of the topics would be handled effectively. </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BannerAndFirstQuestion;