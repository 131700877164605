import React from 'react'
import '../Page/FrequentlyAskedQuestions.css';
import AskedQuestions from './FAQ/AskedQuestions';
import Banner from './FAQ/Banner';
import NeedMoreHelp from './FAQ/NeedMoreHelp';
import { Helmet } from 'react-helmet';

function FrequentlyAskedQuestions() {
  return (
    <>
    <Helmet>
          <title>FAQ | Help Me Write My Dissertation</title>
          <meta name="description" content="Write my dissertation is the best dissertation writing service to hire with affordable prices, unique and quality content, and professional writers!" />
          <meta name="keywords" content="dissertations online, academic dissertation, Dissertation Paper, Dissertation writing Help, Pay someone to write my dissertation" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/faq/" />
        </Helmet>
    <Banner />
    <AskedQuestions />
    <NeedMoreHelp />
    </>
  )
}

export default FrequentlyAskedQuestions;