import React from 'react'
import '../../Page/DissertationTestimonial.css'

function VideoViewCard(props) {
  let {imageSource, content, altTag} = props.data;
  return (
    <>
    <div className="container testimonialCardSection">
      <div className="row justify-content-center">
        <img src={imageSource} alt={altTag} className='testimonialCardImage img-fluid' />
        <div className="card-body testimonialCardBody">
          <p className='testimonialCardPara'>{content}</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default VideoViewCard;