import React from 'react'
import '../DissertationIntroduction.css'
import { NavLink } from 'react-router-dom';

function IntroductionSubject() {
  return (
    <>
    <section className="dissertationIntroductionSubjectSection">
        <div className="container topSubjectsWholeSectionInnerDiv">
            <div className="row justify-content-center">
                    <div className="col-md-2 firstSubject individualSubjectDiv">
                        <div className="firstImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 secondSubject individualSubjectDiv">
                        <div className="secondImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 thirdSubject individualSubjectDiv">
                        <div className="thirdImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 fourthSubject individualSubjectDiv">
                        <div className="fourthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 fifthSubject individualSubjectDiv">
                        <div className="fifthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 sixthSubject individualSubjectDiv">
                        <div className="sixthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 seventhSubject individualSubjectDiv">
                        <div className="seventhImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 eighthSubject individualSubjectDiv">
                        <div className="eighthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 ninthSubject individualSubjectDiv">
                        <div className="ninthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
                    <div className="col-md-2 tenthSubject individualSubjectDiv">
                        <div className="tenthImage"></div>
                        <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
                    </div>
            </div>
        </div>
       </section>
    </>
  )
}

export default IntroductionSubject;