import React from 'react'
import '../Page/DissertationMethodology.css';
import FaqAndDissChaptersComponent from '../CommonPage/FaqAndDissChaptersComponent';
import MethodologyBanner from './Methodology/MethodologyBanner';
import MethodologyFirstThreeQuestions from './Methodology/MethodologyFirstThreeQuestions';
import MethodologyQuestionAndServices from './Methodology/MethodologyQuestionAndServices';
import MethodologyQuestionAndSubjects from './Methodology/MethodologyQuestionAndSubjects';
import { Helmet } from 'react-helmet';

function DissertationMethodology() {
  return (
    <>
    <Helmet>
          <title>Dissertation Methodology : Write My Dissertation For Me</title>
          <meta name="description" content="We offer services for dissertation methodology writing. Hire our industry experts to help you in writing your PhD thesis papers." />
          <meta name="keywords" content="Best Dissertation writing service, dissertation methodology help, dissertation assignment help, hire someone to write my dissertation, Can someone else write my dissertation?" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/dissertationmethodology/" />
        </Helmet>
    <MethodologyBanner />
   <MethodologyFirstThreeQuestions />
    <MethodologyQuestionAndServices />
    <MethodologyQuestionAndSubjects />
     <FaqAndDissChaptersComponent />
    </>
  )
}

export default DissertationMethodology;