

     const yearlySubjectData = [
        {
            Year: 2022,
            management: 125,
            marketing: 98,
            socialScience: 74
        },
        {
            Year: 2021,
            management: 112,
            marketing: 88,
            socialScience: 67
        },
        {
            Year: 2020,
            management: 101,
            marketing: 79,
            socialScience: 60
        },
        {
            Year: 2019,
            management: 91,
            marketing: 71,
            socialScience: 54
        },
        {
            Year: 2018,
            management: 82,
            marketing: 64,
            socialScience: 49
        }
    ];

export default yearlySubjectData;