import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from "react-router-dom";
import bannerImage from '../Image/Dissertation_Subject_Page/banner_image.png';
import clickYesPoint from '../Image/Dissertation_Subject_Page/click_yes_point.png';
import '../Page/DissertationSubject.css';

function DissertationSubject() {
  return (
    <>
    <Helmet>
          <title>Dissertation Subject | Do My Dissertation For Me</title>
          <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
          <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/dissertationsubjects/" />
        </Helmet>
    <section className="dissertationSubjectPageBannerImageSection">
<div className="container-fluid">
    <div className="row justify-content-evenly">
        <div className="col-lg-5 dissertationSubjectPageHeadingAndParaInBannerSection">
            <h1>DIGNISSIM EST A MATTIS IN DOLOR CUM.</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. </p>
            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Affordable Price</span></p>
            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Assured Grade A or B</span></p>
            <p><span><img src={clickYesPoint} alt="" /></span><span className="ms-4">Moneyback Guarantee</span><span className='dissertationSubjectStarMark'>*</span></p>
            <button className="dissertationSubjectPageOrderNowButton"><NavLink className="href" to={"/getQuotePage"}>ORDER NOW</NavLink></button>
        </div>
        <div className="col-lg-5 dissertationSubjectPageLeftImageSection">
            <img src={bannerImage} alt="Best Dissertation writing service" className="img-fluid" />
        </div>
    </div>
</div>
</section>
<section className="topSubjectsUnderDissertationSectionStartHere">
<div className="container">
<div className="row justify-content-center">
    <div className="col-md-12 topDissertationSubjectsHeading">
        <h2>TOP SUBJECTS</h2>
    </div>
        <div className="col-md-2 firstSubject individualSubjectDiv">
            <div className="firstImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 secondSubject individualSubjectDiv">
            <div className="secondImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 thirdSubject individualSubjectDiv">
            <div className="thirdImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 fourthSubject individualSubjectDiv">
            <div className="fourthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 fifthSubject individualSubjectDiv">
            <div className="fifthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 sixthSubject individualSubjectDiv">
            <div className="sixthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 seventhSubject individualSubjectDiv">
            <div className="seventhImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 eighthSubject individualSubjectDiv">
            <div className="eighthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 ninthSubject individualSubjectDiv">
            <div className="ninthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
        <div className="col-md-2 tenthSubject individualSubjectDiv">
            <div className="tenthImage"></div>
            <p><NavLink to={"/sampleSubjectPage"} className="ParaOfSubjectName">SUBJECT NAME</NavLink></p>
        </div>
</div>
</div>
</section>
    </>
  )
}

export default DissertationSubject;