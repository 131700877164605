import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import '../DissertationTestimonial.css'
import imageOne from '../../Image/Testimonial_Page/Video.jpg'
import VideoViewCard from '../../Page/Testimonial/VideoViewCard';

function VideoTestimonial() {
  return (
    <>
    <div className="container justify-content-center pb-4 mb-4 testimonialCardBox">
      <Swiper
      // freeMode={true}
      grabCursor={true}
      modules={[Navigation]}
      navigation={{ clickable: true }}
      className='mySwiper'
      breakpoints={{
        575: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 50
        }
      }}
      >
        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-1"}} /></SwiperSlide>

        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-2"}} /></SwiperSlide>
        
        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-3"}} /></SwiperSlide>

        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-4"}} /></SwiperSlide>

        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-5"}} /></SwiperSlide>

        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-6"}} /></SwiperSlide>

        <SwiperSlide><VideoViewCard data={{imageSource: imageOne, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et in enim, sed egestas id. Morbi ac nec porttitor aliquet cras montes, duis. Velit ut ut maecenas tempus erat iaculis blandit. Amet ultrices eget a et pellentesque", altTag: "Card-7"}} /></SwiperSlide>
      </Swiper>
    </div>
    </>
  )
}

export default VideoTestimonial;