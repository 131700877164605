export const faqMainPageQuestionsData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Is everything confidential?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Of course! Privacy and confidentiality are the two things we swear by. All conversations between you and the expert are kept private and secure. Nothing is shared with any third party. Even the expert working on your project only knows the topic and your feedback. They have no access to your personal information."
      }
    },{
      "@type": "Question",
      "name": "Is it original?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "100%! The writers are trained and moulded to only deliver original content. Our internal quality checks ensure quality and original content. Also, we use software like TurnItIn and Copyscape to back ourselves. We do not even utter the word ‘plagiarism’ in our company."
      }
    },{
      "@type": "Question",
      "name": "What is the money back guarantee policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our experts are well educated from the best universities who are handling your dissertation writing, we Assured Good Grade or Money Back Guarantee*."
      }
    },{
      "@type": "Question",
      "name": "How long will it take to do my dissertation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our commitment is to meet the deadline given by you. Periodic update on the progress being made and the chapters that are being completed will also be shared with you. Feedbacks will also be incorporated in the paper."
      }
    },{
      "@type": "Question",
      "name": "What is the qualification of the experts?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Your most important academic paper (dissertation and capstone) is handled by our most knowledgeable and serious tutors. We take on board tutors who have completed their own PhDs. This gives them a better insight into your requirements."
      }
    },{
      "@type": "Question",
      "name": "What is the quality of work?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "So good that your professor will never ask you to make any changes. We strive to hit the nail at one attempt. Not only with perfect grammar and well-articulated sentences but also with ideas that match the context. Also, each section of the paper is thoroughly reviewed by another expert."
      }
    },{
      "@type": "Question",
      "name": "How do I place an order?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a free quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!"
      }
    },{
      "@type": "Question",
      "name": "Do I need to pay in advance for my dissertation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes! The group possibly begins working when we have received the advance payment. However, if the client is not happy with the received documents, they can request their cash back."
      }
    },{
      "@type": "Question",
      "name": "Do you offer revision with work, if needed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Of course, we are always welcome to drop their feedback to make the document correct accordingly until the client is completely happy."
      }
    },{
      "@type": "Question",
      "name": "What payment methods do you accept?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "For your benefit our organization acknowledges all significant Master Cards. You can pay through a bank Wire Transfer. Kindly contact our experts for more payment information."
      }
    },{
      "@type": "Question",
      "name": "Can I contact my writer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Correspondence with your writer is significant for the successful completion of your projects. We have developed an interesting and simple system framework where you can speak with your writer, any questions you can contact our experts will be available 24/7."
      }
    },{
      "@type": "Question",
      "name": "Can I get my dissertation work in chapters?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We influence our clients completely. We convey work in sections or parts. By doing this, the client can effectively construct their trust over us, and be all the more certain to recruit us or not. We feel respected due to each client who employed us for a solitary section and got their dissertation finished by us."
      }
    }]
  }
  


// export const faqMainPageQuestionsData = 
// {
//     "$schema": "http://json-schema.org/draft-04/schema#",
//     "type": "FAQPage",
//     "items": [
//       {
//         "type": "FAQPage",
//         "properties": {
//           "id": {
//             "type": 1
//           },
//           "question": {
//             "type": "Is everything confidential?"
//           },
//           "answer": {
//             "type": "Of course, our agenda is to provide the best service with 100% confidentiality to the students. All the conversation between you and the writers will be kept private and secure, and the data is kept securely from third parties who cannot access any information."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 2
//           },
//           "question": {
//             "type": "Is plagiarism free?"
//           },
//           "answer": {
//             "type": "Yes, our expert dedicated quality assurance team checks every order using the most authentic plagiarism detection tools to make sure it is 100% plagiarism-free. We have never received any complaints regarding plagiarism or copied content."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 3
//           },
//           "question": {
//             "type": "What is the money back guarantee policy?"
//           },
//           "answer": {
//             "type": "Our experts are well educated from the best universities who are handling your dissertation writing, we Assured Good Grade or Money Back Guarantee*."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 4
//           },
//           "question": {
//             "type": "How long will it take to do my dissertation?"
//           },
//           "answer": {
//             "type": "We have skilled educated experts who can tackle your task on each and every step, that your fulfillment is our need. The vast majority of our orders are finished and delivered to clients with time to spare so you can demand changes whenever required from our experts."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 5
//           },
//           "question": {
//             "type": "What are the qualifications of tutors?"
//           },
//           "answer": {
//             "type": "Your Dissertation is the most serious academic project for your career in dissertation writing. We completely Understand this and your need to have quality dissertation writers. We assign your work to the expert with the most experienced PhD holders in the specific field."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 6
//           },
//           "question": {
//             "type": "Is the quality of work at university standards ?"
//           },
//           "answer": {
//             "type": "We will assign a Ph.D educated writer with experience in your particular area of academic. The dissertation writing service will complete with accurate deadlines assigned. Before the paper arrives at your email we ensure the cross check of all the fields from the experts is 100% genuine work."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 7
//           },
//           "question": {
//             "type": "How do I place an order?"
//           },
//           "answer": {
//             "type": "It's a stepping stone to your career by contacting our experts, fill all your necessary details in the contact form, our experts will contact you immediately with an affordable price."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 8
//           },
//           "question": {
//             "type": "Do I need to pay in advance for my dissertation?"
//           },
//           "answer": {
//             "type": "Yes! The group possibly begins working when we have received the advance payment. However, if the client is not happy with the received documents, they can request their cash back."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 9
//           },
//           "question": {
//             "type": "Do you offer revision with work, if needed?"
//           },
//           "answer": {
//             "type": "Of course, we are always welcome to drop their feedback to make the document correct accordingly until the client is completely happy."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 10
//           },
//           "question": {
//             "type": "What payment methods do you accept?"
//           },
//           "answer": {
//             "type": "For your benefit our organization acknowledges all significant Master Cards. You can pay through a bank Wire Transfer. Kindly contact our experts for more payment information."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 11
//           },
//           "question": {
//             "type": "Can I contact my writer?"
//           },
//           "answer": {
//             "type": "Correspondence with your writer is significant for the successful completion of your projects. We have developed an interesting and simple system framework where you can speak with your writer, any questions you can contact our experts will be available 24/7."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       },
//       {
//         "type": "object",
//         "properties": {
//           "id": {
//             "type": 12
//           },
//           "question": {
//             "type": "Can I get my dissertation work in chapters?"
//           },
//           "answer": {
//             "type": "We influence our clients completely. We convey work in sections or parts. By doing this, the client can effectively construct their trust over us, and be all the more certain to recruit us or not. We feel respected due to each client who employed us for a solitary section and got their dissertation finished by us."
//           }
//         },
//         "required": [
//           "id",
//           "question",
//           "answer"
//         ]
//       }
//     ]
//   }
