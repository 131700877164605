import React from 'react'
import '../../Page/Dissertation_Subject_Page/MainSubjectPage.css'
import Guarantees from '../LiteratureReview/Guarantees';
import FaqComponent from './FaqComponent';
import firstSubjectImage from '../../Image/Dissertation_Subject_Page/Calculus.png'
import secondSubjectImage from '../../Image/Dissertation_Subject_Page/Accounts.png'
import thirdSubjectImage from '../../Image/Dissertation_Subject_Page/Book_Icon_Old.png'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function SampleSubjectPage() {
    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
      }, []);
  return (
    <>
    <Helmet>
          <title>Dissertation Subject | Do My Dissertation For Me</title>
          <meta name="description" content="With our essay writing service, you get the best. And indeed, we are the best custom dissertation writing service with an affordable price." />
          <meta name="keywords" content="i need help with my dissertation, Do My Dissertation For Me, write my dissertation for me, Pay someone to write my dissertation, Can someone else write my dissertation?" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/samplesubjectpage/" />
        </Helmet>
    <section>
        <div className="container">
            <div className="row justify-content-evenly">
                <div className="col-md-7 col-12">
                   <div className='subjectHeadingParaAndButtonDiv'>
                   <h2>HEADING</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                   <button className='subjectPageKnowMoreButton knowMoreButtonForTopMargin'>KNOW MORE</button>
                   </div>
                   <div className='subjectHeadingParaAndButtonDiv'>
                   <h2>HEADING</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                   <button className='subjectPageKnowMoreButton knowMoreButtonForTopMargin'>KNOW MORE</button>
                   </div>
                   <div className='subjectHeadingParaAndButtonDiv'>
                   <h2>HEADING</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                   <button className='subjectPageKnowMoreButton knowMoreButtonForTopMargin'>KNOW MORE</button>
                   </div>
                   <div className='subjectHeadingParaAndButtonDiv'>
                   <h2>HEADING</h2>
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                   <button className='subjectPageKnowMoreButton knowMoreLastButtonOfThisPage'>KNOW MORE</button>
                   </div>
                </div>
                <div className="col-md-5 col-12 subjectPageRightSideDivForIconAndName">
                    <div className="firstSubjectSideBar subjectImageIconAndNameDiv">
                        <div className='doubleLineBoxDiv'>
                        <div className="firstLineBox"></div>
                        <div className="secondLineBox"></div>
                        </div>
                        <img src={thirdSubjectImage} className="backgroundImageIcon" alt="Calculus" />
                        <div className='subjectIconAndNameDiv'>
                        <img src={firstSubjectImage} className="subjectImageIcon" alt="Calculus" />
                        <p className='subjectName'>SUBJECT NAME</p>
                        </div>
                    </div>
                    <div className="secondSubjectSideBar subjectImageIconAndNameDiv">
                    <div className='doubleLineBoxDiv'>
                        <div className="firstLineBox"></div>
                        <div className="secondLineBox"></div>
                        </div>
                    <img src={thirdSubjectImage} className="backgroundImageIcon" alt="Calculus" />
                        <div className='subjectIconAndNameDiv'>
                        <img src={secondSubjectImage} className="subjectImageIcon" alt="Calculus" />
                        <p className='subjectName'>SUBJECT NAME</p>
                        </div>
                    </div>
                    <div className="thirdSubjectSideBar subjectImageIconAndNameDiv">
                    <div className='doubleLineBoxDiv'>
                        <div className="firstLineBox"></div>
                        <div className="secondLineBox"></div>
                        </div>
                    <img src={thirdSubjectImage} className="backgroundImageIcon" alt="Calculus" />
                        <div className='subjectIconAndNameDiv'>
                        <img src={firstSubjectImage} className="subjectImageIcon" alt="Calculus" />
                        <p className='subjectName'>SUBJECT NAME</p>
                        </div>
                    </div>
                    <div className="fourthSubjectSideBar subjectImageIconAndNameDiv">
                    <div className='doubleLineBoxDiv'>
                        <div className="firstLineBox"></div>
                        <div className="secondLineBox"></div>
                        </div>
                    <img src={thirdSubjectImage} className="backgroundImageIcon" alt="Calculus" />
                        <div className='subjectIconAndNameDiv'>
                        <img src={secondSubjectImage} className="subjectImageIcon" alt="Calculus" />
                        <p className='subjectName'>SUBJECT NAME</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Guarantees />
    <FaqComponent />
    </>
  )
}

export default SampleSubjectPage;