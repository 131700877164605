import React from 'react';
import { NavLink } from 'react-router-dom';
import '../DissertationProposal.css';

function ProposalFirstThreeQuestion() {
  return (
    <>
    <section className="dissertationProposalFirstThreeQuestionSection">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 dissertationProposalHeadingAndParaSection">
                    <h2>WHAT IS DISSERTATION PROPOSAL ?</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                </div>
                <div className="col-md-12 dissertationProposalHeadingAndParaSection">
                    <h2>HOW DO I START DISSERTATION PROPOSAL ?</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                    <button className="mt-3 mb-5"><NavLink to={"/getQuote"} className="href">GET FREE QUOTE</NavLink></button>
                </div>
                <div className="col-md-12 dissertationProposalHeadingAndParaSection">
                    <h2>WHAT TO INCLUDE IN A DISSERTATION PROPOSAL ?</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas risus mauris. Purus cursus egestas blandit vestibulum pulvinar id mattis. Bibendum lectus pulvinar aenean aliquam tortor mi. Venenatis mi feugiat duis sit vitae ut lorem in. A praesent pharetra dictumst nulla morbi non tristique id at.</p>
                    <button className="mt-3 mb-5"><NavLink to={"/getQuote"} className="href">GET DISSERTATION HELP NOW</NavLink></button>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ProposalFirstThreeQuestion;