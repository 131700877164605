import React from 'react'

function Quote() {
  return (
    <>
    <section className="dissertationTestimonialQuoteSectionStartHere mb-4 pb-4">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <p className="dissertationTestimonialQuote">
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu pellentesque vivamus ultrices lectus ut nec donec mattis morbi. Ut vel risus, sagittis, tincidunt facilisi. Tellus, adipiscing accumsan tincidunt tellus egestas.”
                    </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Quote;