import React from 'react'
import '../Page/DissertationConclusion.css';
import CarouselComponent from '../CommonPage/CarouselComponent';
import FaqAndDissChaptersComponent from '../CommonPage/FaqAndDissChaptersComponent';
import ConclusionBanner from './Conclusion/ConclusionBanner';
import ConclusionFirstTwoQuestion from './Conclusion/ConclusionFirstTwoQuestion';
import { Helmet } from 'react-helmet';

function DissertationConclusion() {
  return (
    <>
    <Helmet>
          <title>Dissertation Conclusion Writing Service | Hire An Expert</title>
          <meta name="description" content="Hire an expert professional writer experienced in writing researh papers to write dissertation conclusion." />
          <meta name="keywords" content="Can I pay someone to write my dissertation for me?, pay someone to write my dissertation, take my online dissertation, write my dissertation for me, dissertation conclusion" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/dissertationconclusion/" />
        </Helmet>
    <ConclusionBanner />
    <ConclusionFirstTwoQuestion />
    {/* <TestimonialComponent /> */}
    <CarouselComponent />
    <FaqAndDissChaptersComponent />
    </>
  )
}

export default DissertationConclusion;