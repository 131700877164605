import React from 'react'
import { NavLink } from 'react-router-dom';
import '../CommonPage/FaqAndDissChaptersComponent.css'
import arrowIcon from '../Image/Dissertation_Literature_Review/Arrow.png';

function FaqAndDissChaptersComponent() {
  return (
    <>
    <section className="commonComponentFaqAndDissertationChapterSection">
      <div className="container">
          <div className="row justify-content-between">
              <div className="col-lg-6 col-12 commonComponentFaqSection">
                  <h2>HAVE ANY QUESTIONS</h2>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Is Everything confidential ?
                          </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">Of course, our agenda is to provide the best service with 100% confidentiality to the students. All the conversation between you and the writers will be kept private and secure, and the data is kept securely from third parties who cannot access any information.</div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Is plagiarism free ?
                          </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                          <div className="accordionName-body">Yes, our expert dedicated quality assurance team checks every order using the most authentic plagiarism detection tools to make sure it is 100% plagiarism-free. We have never received any complaints regarding plagiarism or copied content. </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          How long will it take to do my Dissertation ?
                          </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">We have skilled educated experts who can tackle your task on each and every step, that your fulfillment is our need. We work to complete your task before the deadlines. The vast majority of our orders are finished and delivered to clients with time to spare so you can demand changes whenever required from our experts.</div>
                        </div>
                      </div>
                    </div>
                    <p className="commonComponentReadMoreOnFaqSection"><span><NavLink to={"/faq"} className="href">Read More on FAQ</NavLink></span></p>
              </div>
              <div className="col-lg-5 col-10 commonComponentChapters">
                <h4>DISSERTATION CHAPTERS</h4>
                <ul>
                    <li className="mt-3"><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationAbstract"} className="href ms-2">Dissertation Abstract</NavLink></li>            
                    <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationIntroduction"} className="href ms-2">Dissertation Introduction</NavLink></li>            
                    <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationLiteratureReview"} className="href ms-2">Dissertation Literature Review</NavLink></li>            
                    <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationMethodology"} className="href ms-2">Dissertation Methodology</NavLink></li>            
                    {/* <li><img src={arrowIcon} alt="ICON" /><a href="/" className="href ms-2">Dissertation Results</a></li> */}
                    {/* <li><img src={arrowIcon} alt="ICON" /><a href="/" className="href ms-2">Dissertation Discussions</a></li> */}
                    {/* <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationDataAnalysis"} className="href ms-2">Dissertation Data Analyst &amp; Results</NavLink></li> */}
                    <li><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationConclusion"} className="href ms-2">Dissertation Conclusions</NavLink></li>            
                    <li className="mb-3"><img src={arrowIcon} alt="Pay someone to write my dissertation" /><NavLink to={"/dissertationBibliography"} className="href ms-2">Dissertation Bibliography</NavLink></li>            
                </ul>
              </div>
          </div>
      </div>
  </section>
    </>
  )
}

export default FaqAndDissChaptersComponent;