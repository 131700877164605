import React from 'react'
import '../AboutUsPage.css'
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function AboutUsCarousel() {
  return (
    <>
    <section className="reviewsContainerStartHere">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div id="carouselExampleCaptions" className="carousel aboutUsPageMainCarousel slide" data-bs-ride="false">
                        {/* <!-- <div className="carousel-indicators">
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div> --> */}
                        <div className="carousel-inner aboutUsPageInnerPartOfCarousel">
                          <div className="carousel-item dissertationAboutUsCarouselItems active">
                            <div className="carousel-caption">
                              <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu pellentesque vivamus ultrices lectus ut nec donec mattis morbi. Ut vel risus, sagittis, tincidunt facilisi. Tellus, adipiscing accumsan tincidunt tellus egestas.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu pellentesque vivamus ultrices lectus ut nec donec mattis morbi. Ut vel risus, sagittis, tincidunt facilisi. Tellus, adipiscing accumsan tincidunt tellus egestas.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                          <div className="carousel-item dissertationAboutUsCarouselItems">
                            <div className="carousel-caption">
                              <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu pellentesque vivamus ultrices lectus ut nec donec mattis morbi. Ut vel risus, sagittis, tincidunt facilisi. Tellus, adipiscing accumsan tincidunt tellus egestas.”</p>
                            <div className="below-heading-line-carousel mt-2"><span></span></div>
                            </div>
                          </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"><i className="bi bi-chevron-left"><BsChevronLeft /></i></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"><i className="bi bi-chevron-right"><BsChevronRight /></i></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutUsCarousel;