import React from 'react'
import '../Page/DissertationBibliography.css';
import CarouselComponent from '../CommonPage/CarouselComponent';
import FaqAndDissChaptersComponent from '../CommonPage/FaqAndDissChaptersComponent';
import BibliographyFirstTwoQuestions from './Bibliography/BibliographyFirstTwoQuestions';
import BibliographyBanner from './Bibliography/BibliographyBanner';
import { Helmet } from 'react-helmet';

function DissertationBibliography() {
  return (
    <>
    <Helmet>
          <title>Dissertation Bibliography writing | Hire Professional Writer</title>
          <meta name="description" content="Hire professional writer to write your dissertation bibliography. Experienced PhD writers, affordable price, getting good grades." />
          <meta name="keywords" content="Dissertation Paper, thesis writing help, write my thesis, dissertation help online, dissertation services" />
          <link rel="canonical" href=" https://www.writemydissertationforme.com/dissertationbibliography/" />
        </Helmet>
    <BibliographyBanner />
    <BibliographyFirstTwoQuestions />
    {/* <TestimonialComponent /> */}
    <CarouselComponent />
    <FaqAndDissChaptersComponent />
    </>
  )
}

export default DissertationBibliography