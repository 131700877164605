import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import leftSideImageOfGirl from '../../Image/Dissertation_Abstract/Abstract.jpg';
import arrowInSideBar from '../../Image/Dissertation_Literature_Review/Arrow.png';
import '../DissertationAbstract.css';

function AbstractLastSection() {
  return (
    <>
    <section className="dissertationAbstractLastThreeQuestionAndFaqSection">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6 col-12 dissertationAbstractFourthFifthSixthAndFaqSection">
                    <h2>QUICK TIPS TO WRITE A HIGH QUALITY DISSERTATION ABSTRACT ?</h2>
                    <p>Dissertation abstract plays the role of developing initial interests among the readers about the core topics which are part of the dissertation study. It is used for enhancing the process in which dissertation topics will be properly evaluated within the summary of the project. The abstract will form the basis in which the introduction of the topic will be engaged.</p>
                    <p>Dissertation writing help tutors suggest that the abstract should highlight the purpose of the research along with the main findings and conclusion of the project. During the course of explaining the abstract content, it will also be essential to explain the research methodology which has been used within the dissertation project. The use of the right summary for the dissertation project will establish instant connections with the readers. As such, a planned approach to dissertation abstract is essential to plan the content management within project work.</p>
                    <h2>WHY IS IT IMPORTANT TO WRITE A GOOD DISSERTATION ABSTRACT ?</h2>
                    <p>Dissertation abstract is an essential component of dissertation project work. It creates confidence about the quality of the research and methodology used within the project. Dissertations make use of complex research methodologies which will have to be explained in a summarized manner within the dissertation abstract to ensure that the interest of the readers of the dissertation work will be engaged in the right manner.</p>
                    <p>An ideal Dissertation Abstract Length is supposed to be around 250-300 words. It should provide a fair idea about the thesis and research approach to the examiner and readers. Without the development of an effective dissertation abstract, it will be difficult to initiate initial opinion within the reading process. Have queries about the process in which a dissertation abstract can be tackled? Let our experts help you.</p>
                    <h2>BEST DISSERTATION WRITING SERVICES</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas </p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis enim turpis suspendisse eget risus auctor eget nulla. Nisl vestibulum faucibus duis integer quis elit diam scelerisque. Diam mattis tempor at orci ac. Euismod platea cras molestie tincidunt egestas </p>
                    <button className="dissertationAbstractGetDissertationHelpNow"><Link to={"/getQuote"}>GET DISSERTATION HELP NOW</Link></button>
                    <h2>HAVE ANY QUESTIONS</h2>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                              Is Everything confidential ?
                            </button>
                          </h2>
                          <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Of course, our agenda is to provide the best service with 100% confidentiality to the students. All the conversation between you and the writers will be kept private and secure, and the data is kept securely from third parties who cannot access any information.</div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                              Is plagiarism free ?
                            </button>
                          </h2>
                          <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Yes, our expert dedicated quality assurance team checks every order using the most authentic plagiarism detection tools to make sure it is 100% plagiarism-free. We have never received any complaints regarding plagiarism or copied content. </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                              How long will it take to do my Dissertation ?
                            </button>
                          </h2>
                          <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">We have skilled educated experts who can tackle your task on each and every step, that your fulfillment is our need. We work to complete your task before the deadlines. The vast majority of our orders are finished and delivered to clients with time to spare so you can demand changes whenever required from our experts.</div>
                          </div>
                        </div>
                      </div>
                      <p className="dissertationAbstractReadMoreOnFaqSection"><span><NavLink to={"/faq"} className="href">Read More on FAQ</NavLink></span></p>
                </div>
                <div className="col-lg-5 col-12 dissertationAbstractWritingServicesAndChapters">
                    <h4>DISSERTATION WRITING SERVICES</h4>
                    <ul className="dissertationAbstractWritingServices">
                        <li className="mt-4"><img src={arrowInSideBar} alt="" /><a href="/" className="href ms-2"><span>Write My Dissertation</span></a></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Buy Dissertation</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationWritingServices"} className="href ms-2"><span>Dissertation Assignment Help</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationAbstract"} className="href ms-2"><span>Dissertation Abstract</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationWritingServices"} className="href ms-2"><span>Dissertation Help</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Hire Someone To Write My <span className="dissertationPara">Dissertation</span></span></NavLink></li>                
                        <li className="mb-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/getQuote"} className="href ms-2"><span>Take My Online Dissertation</span></NavLink></li>                
                    </ul>
                    <img src={leftSideImageOfGirl} alt="Pay someone to write my dissertation" className="img-fluid my-5 dissertationAbstractLeftSideOfGirlImage" style={{height: "301px"}} />
                    <h4 className="mt-5">DISSERTATION CHAPTERS</h4>
                    <ul className="dissertationAbstractChapters">
                        <li className="mt-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationProposal"} className="href ms-2"><span>Dissertation Proposal</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationIntroduction"} className="href ms-2"><span>Dissertation Introduction</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationLiteratureReview"} className="href ms-2"><span>Dissertation Literature Review</span></NavLink></li>            
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationMethodology"} className="href ms-2"><span>Dissertation Methodology</span></NavLink></li>
                        <li><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationConclusion"} className="href ms-2"><span>Dissertation Conclusions</span></NavLink></li>            
                        <li className="mb-4"><img src={arrowInSideBar} alt="" /><NavLink to={"/dissertationBibliography"} className="href ms-2"><span>Dissertation Bibliography</span></NavLink></li>            
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AbstractLastSection;