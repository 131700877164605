import React from 'react'
import { faqMainPageQuestionsData } from '../../Data/FAQ_Main_Page/FaqMainPageQuestionsData';
import '../FrequentlyAskedQuestions.css'

function AskedQuestions() {
    const articleRenderedData = [
        ({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "Is everything confidential?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Of course! Privacy and confidentiality are the two things we swear by. All conversations between you and the expert are kept private and secure. Nothing is shared with any third party. Even the expert working on your project only knows the topic and your feedback. They have no access to your personal information."
                }
            }, {
                "@type": "Question",
                "name": "Is it original?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "100%! The writers are trained and moulded to only deliver original content. Our internal quality checks ensure quality and original content. Also, we use software like TurnItIn and Copyscape to back ourselves. We do not even utter the word ‘plagiarism’ in our company."
                }
            }, {
                "@type": "Question",
                "name": "What is the money back guarantee policy?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our experts are well educated from the best universities who are handling your dissertation writing, we Assured Good Grade or Money Back Guarantee*."
                }
            }, {
                "@type": "Question",
                "name": "How long will it take to do my dissertation?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our commitment is to meet the deadline given by you. Periodic update on the progress being made and the chapters that are being completed will also be shared with you. Feedbacks will also be incorporated in the paper."
                }
            }, {
                "@type": "Question",
                "name": "What is the qualification of the experts?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Your most important academic paper (dissertation and capstone) is handled by our most knowledgeable and serious tutors. We take on board tutors who have completed their own PhDs. This gives them a better insight into your requirements."
                }
            }, {
                "@type": "Question",
                "name": "What is the quality of work?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "So good that your professor will never ask you to make any changes. We strive to hit the nail at one attempt. Not only with perfect grammar and well-articulated sentences but also with ideas that match the context. Also, each section of the paper is thoroughly reviewed by another expert."
                }
            }, {
                "@type": "Question",
                "name": "How do I place an order?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a free quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!"
                }
            }, {
                "@type": "Question",
                "name": "Do I need to pay in advance for my dissertation?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes! The group possibly begins working when we have received the advance payment. However, if the client is not happy with the received documents, they can request their cash back."
                }
            }, {
                "@type": "Question",
                "name": "Do you offer revision with work, if needed?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Of course, we are always welcome to drop their feedback to make the document correct accordingly until the client is completely happy."
                }
            }, {
                "@type": "Question",
                "name": "What payment methods do you accept?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "For your benefit our organization acknowledges all significant Master Cards. You can pay through a bank Wire Transfer. Kindly contact our experts for more payment information."
                }
            }, {
                "@type": "Question",
                "name": "Can I contact my writer?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Correspondence with your writer is significant for the successful completion of your projects. We have developed an interesting and simple system framework where you can speak with your writer, any questions you can contact our experts will be available 24/7."
                }
            }, {
                "@type": "Question",
                "name": "Can I get my dissertation work in chapters?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We influence our clients completely. We convey work in sections or parts. By doing this, the client can effectively construct their trust over us, and be all the more certain to recruit us or not. We feel respected due to each client who employed us for a solitary section and got their dissertation finished by us."
                }
            }]
        })
    ]
    return (
        <>
            <script type="application/ld+json">
                {JSON.stringify(articleRenderedData)}
            </script>
            <section className="faqPageSectionStartHere">
                <div className="container faqPageFirstInnerSectionStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 faqPageHeading">
                            <h2><span>QUICK ASSIST</span></h2>
                            <p>Answer to our most Frequently Asked Questions are just one click away.</p>
                        </div>
                        <hr />
                        {
                            faqMainPageQuestionsData.mainEntity.map((questionsData, index) => (
                                <>
                                    <div className="col-lg-5 col-md-5 col-xl-4 faqPageFaqSection" key={index}>
                                        <h5>{questionsData.name}</h5>
                                        <p>{questionsData.acceptedAnswer.text}</p>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default AskedQuestions;